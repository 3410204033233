import { UseCasesResponse } from '@/@core/models/api';
import { ExecuteUsecaseResponse } from '@/@core/models/api/usecase-qa';
import { HTTP_CLIENT } from '@/@core/services/api/httpClient';
import { Http } from '@aleph-alpha/lib-http';

export class UseCaseService {
  constructor(readonly httpClient: Http) {}

  async getUseCases(): Promise<UseCasesResponse[]> {
    const response = await HTTP_CLIENT.get<UseCasesResponse[]>('usecases');
    return response.data;
  }
}

export const USE_CASE_SERVICE = new UseCaseService(HTTP_CLIENT);

// TODO for "old" kernel use-cases
export const executeUsecase = async (
  usecaseId: string,
  question: string,
  file?: File,
  namespace?: string,
  collection?: string,
  fallbackLanguage?: string
): Promise<ExecuteUsecaseResponse> => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  let body: any = { question, namespace, collection, fallback_language: fallbackLanguage };

  if (file) {
    const formData = new FormData();
    formData.append('question', question);
    formData.append('file', file);
    if (fallbackLanguage) formData.append('fallbackLanguage', fallbackLanguage);
    body = formData;
  }

  const response = await HTTP_CLIENT.post<ExecuteUsecaseResponse>(`usecases/${usecaseId}/execute`, {
    body,
  });

  return response.data;
};
