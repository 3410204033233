import { sidebarIconClasses } from '@/@core/components/Sidebar/classes';
import { SidebarItem } from '@/@core/components/Sidebar/types';
import { USECASE_ENTRY_JAVASCRIPT } from '@/@core/constants/useCase';
import { USE_CASE_SERVICE } from '@/@core/services/api/UseCaseService';
import { defineStore } from 'pinia';
import { __federation_method_setRemote } from 'virtual:__federation__';
import { ref, useId } from 'vue';

export const useUseCaseStore = defineStore('usecases', () => {
  const useCases = ref<SidebarItem[]>([]);

  async function fetch() {
    useCases.value = [];

    const vueId = useId();

    const response = await USE_CASE_SERVICE.getUseCases();

    // To be removed: Temporarily add a local use case for development if the corresponding ENV is set
    if (
      import.meta.env.VITE_ENABLE_TEMPLATE_USE_CASES != null &&
      import.meta.env.VITE_ENABLE_TEMPLATE_USE_CASES.toLowerCase() === 'true'
    ) {
      response.push({
        id: 'localusecase',
        name: 'Custom App Local',
        url: 'http://localhost:8080',
      });
    }

    for (const useCase of response) {
      __federation_method_setRemote(useCase.id, {
        // ToDo (AST-1212): should be proxied via Assistant-API in the future
        url: `${useCase.url}${USECASE_ENTRY_JAVASCRIPT}?v=${vueId}`,
        format: 'esm',
        from: 'vite',
      });

      // TODO: temporary fix to handle "old" kernel use-cases
      if (useCase.name.toLowerCase().startsWith('custom')) {
        useCases.value.push({
          label: useCase.name,
          link: `/usecases/${useCase.id}`,
          icon: `i-material-symbols-smart-toy-outline ${sidebarIconClasses}`,
        });
      } else {
        useCases.value.push({
          label: useCase.name,
          link: `/usecases/qa/${useCase.id}`,
          icon: `i-material-symbols-smart-toy-outline ${sidebarIconClasses}`,
        });
      }
    }
  }

  return {
    useCases,
    fetch,
  };
});
